import React, { useState } from "react";
import Modal from "../components/Modal";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import Img1 from "../asset/images/page1-img_02-01.jpg"
import Img6 from "../asset/images/page1-img_02-02.jpg"
import Img2 from "../asset/images/page1-img_06.jpg"
import Img4 from "../asset/images/page1-img_08.jpg"
import Img3 from "../asset/images/page1-img_07.png"
import Img5 from "../asset/images/page1-img_14.png"
import Banner from "../asset/images/test-main.jpg";
import BannerMob from "../asset/images/home-mob.jpg";

const Home = () => {
  const [isVisible, setIsVisible] = useState(false);
  const closeModalHandler = () => {
    setIsVisible(!isVisible)
  }

  return (
    <div>
      <Menu></Menu>
      {isVisible &&
        <Modal closeModalHandler={closeModalHandler}></Modal>
      }

      <div className="clearfix"></div>
      <div className="banner relative">
                <img src={Banner} alt="banner" className="BannerImg mobHide" />
                <img src={BannerMob} alt="banner" className="BannerImg mobShow" />
                <div className="bannerText mobHide">
                    <div className="container">
                        <div className="row">
                            {/* <div className="col-8 mobHide"></div> */}
                            <div className="col-4 banner_text_container innerPage_Cont">
                                <p><span className="brand_name">ACUTOK</span> <br />
                                    <span className="brand_slogan">POWER UP</span></p>
                                <h3 className="brand_tag redColor">Ride the Future</h3>
                                <h6 className="brand_des">Come and experience it all yourself,
                                    at the greatest motorcycle in the world!</h6>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="bottomGradient"></div> */}
                {/* <div className="headerWave">
                    <div>
                        <svg className="waves" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
                            <defs>
                                <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
                            </defs>
                            <g className="parallax">
                                <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(0,0,0,0.7"></use>
                                <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(0,0,0,0.5)"></use>
                                <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(0,0,0,0.3)"></use>
                                <use xlinkHref="#gentle-wave" x="48" y="7" fill="#000"></use>
                            </g>
                        </svg>
                    </div>

                </div> */}
            </div>
      {/* <div className="top_banner_home">
        <div className="container">
          <div className="row">
            <div className="col-8 mobHide">

            </div>
            <div className="col-4 banner_text_container">
              <p><span className="brand_name">ACUTOK</span> <br />
                <span className="brand_slogan">POWER UP</span></p>
              <h3 className="brand_tag redColor">Ride the Future</h3>
              <h6 className="brand_des">Come and experience it all yourself,
                at the greatest motorcycle in the world!</h6>
              
            </div>
          </div>
        </div>
      </div> */}

      <section className="well-xl bg-color-1 well-sm padding-bottom-mx">
        <div className="container text-center relative">
          <span className="counter counterCount1"></span>
          <div className="number_section"><h4 className="heading-4">The sensation</h4>
            <h3 className="heading-3 redColor"> ACUTOK STARK</h3>
          </div>
          <p>The Stark Electric Bike is the perfect blend of cutting-edge technology, sleek design, and sustainable performance. Engineered for both city commutes and off-road adventures, this e-bike delivers a smooth, responsive ride with the power of a high-torque motor and a long-lasting battery. Its lightweight frame ensures easy handling, while the durable components are built to withstand various terrains. Whether you’re navigating city streets or exploring trails, the Stark offers exceptional speed, range, and comfort. With features like intuitive pedal assist, regenerative braking, and a customizable riding mode, the Stark is designed for cyclists who crave performance without compromising on style or eco-friendliness.</p>
        </div>
      </section>
      <section className="relative">
        <img src={Img6} className="fullWidth" />
        <div className="banner_Float_div mobHide">
          <p><span className="brand_name redColor">STARK</span> <br />
            <span className="brand_slogan">LAUNCHED</span></p>
          <h3 className="brand_tag">Electric Thrills</h3>
          <h6 className="brand_des">Perfect blend of cutting-edge technology, sleek design, and sustainable performance</h6>
        </div>
      </section>
      <section className="well-xl bg-color-1 well-sm padding-bottom-mx">
        <div className="container text-center relative">
          <span className="counter counterCount2"></span>
          <div className="number_section"><h4 className="heading-4">The sensation</h4>
            <h3 className="heading-3 redColor">ACUTOK STORM</h3>
          </div>
          <p>The Storm Electric Bike is built to dominate any terrain, combining power, precision, and versatility in one sleek package. Designed for the adventurous rider, the Storm features a robust motor that effortlessly tackles steep climbs and rugged trails, while its high-capacity battery ensures long-distance rides without interruption. The bike’s aerodynamic frame offers both strength and agility, making it equally suited for urban commuting and off-road exploration. With advanced suspension, intuitive pedal assist, and seamless shifting, the Storm provides a smooth, controlled ride in all conditions. </p>
        </div>
      </section>
      <section className="relative">
        <img src={Img1} className="fullWidth" />
        <div className="banner_Float_div mobHide">
          <p><span className="brand_name redColor">STORM</span><br />
            <span className="brand_slogan">LAUNCHED</span></p>
          <h3 className="brand_tag">Limitless Freedom</h3>
          <h6 className="brand_des">Engineered to deliver unmatched performance, offering riders the freedom</h6>
        </div>
      </section>
      <section className="relative">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-sm-12 bgWhite paddCN">
              <div className="padCsBox">
                <p><span className="brand_name">LICTH</span><br />
                  <span className="brand_slogan">LAUNCHED</span></p>
                <h3 className="brand_tag">Feel the Surge</h3>
                <h6 className="brand_des">Delivers a smooth, responsive ride with the power of a high-torque motor and a long-lasting battery.</h6>
              </div> 
              <img src={Img2} className="BannerImg" />
            </div>
            <div className="col-md-6 col-sm-12 paddCN">
              <div className="padCsBox">
                <p><span className="brand_name fontWhite">LEISTUNG</span> <br />
                  <span className="brand_slogan fontWhite">LAUNCHED</span></p>
                <h3 className="brand_tag fontWhite">Adrenaline Ready</h3>
                <h6 className="brand_des fontWhite">High-capacity battery ensures long-distance rides without interruption.</h6>
              </div>
              <img src={Img3} className="BannerImg" />
            </div>
          </div>
        </div>
      </section>
      <section className="well-xl bg-color-1 well-sm padding-bottom-mx btm3" style={{paddingBottom: 0}}>
        <div className="container text-center relative">
          <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6 col-sm-12">
            <p><span className="brand_name">GLATT</span><br />
              <span className="brand_slogan">LAUNCHED</span></p>
            <h3 className="brand_tag">Eco-Friendly</h3>
            <h6 className="brand_des">Empowers you to go further with less effort—faster, smarter, and greener.</h6>
            <img src={Img5} className="BannerImg" />
          </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </div>
  );
};

export default Home;
