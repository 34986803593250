import React, { useState } from 'react';
import Banner from "../Assets/banner2.jpg";
import Menu from '../components/Menu';
import Footer from '../components/Footer';
const Terms = () => {

    return (
        <>
            <Menu></Menu>
            <div className="clearfix"></div>
      <div className="banner relative">
        <img src={Banner} alt="banner" className="BannerImg mobHide" />
        <img src={Banner} alt="banner" className="BannerImg mobShow" />
        <div className="bannerText mobHide">
          <h1 className="title">Terms & Conditions..</h1>
        </div>
        <div className="bottomGradient"></div>
      </div>
      <div className="clearfix"></div>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <p className='pageData'>
                            <h4 className='pageTitle'>Welcome to Acutok</h4>
                            These terms and conditions outline the rules and regulations for the use of Acutok’s website, located at https://acutok.com. By accessing this website, you acknowledge and accept these terms and conditions. If you do not agree with any part of these terms, please refrain from using Acutok.
                            <p >
                                Acutok employs the use of cookies. By accessing our website, you consent to the use of cookies in accordance with Acutok’s Privacy Policy. Most interactive websites utilize cookies to retrieve user details with each visit. Our website uses cookies to enable the functionality of certain areas, enhancing the experience for visitors. Some of our affiliate and advertising partners may also utilize cookies.
                            </p>

                            <p >
                                Unless otherwise stated, Acutok and/or its licensors own the intellectual property rights for all material on our site. All intellectual property rights are reserved, allowing you to access this content solely for personal use, subject to the restrictions outlined in these terms. You may not republish, sell, rent, sub-license, reproduce, duplicate, or redistribute any material from Acutok.
                                <br /><br />
                                <p>
                                    Certain parts of this website offer users the opportunity to post and exchange opinions and information. Acutok does not filter, edit, publish, or review comments prior to their appearance on the site. The views expressed in comments are those of the individuals posting them and do not reflect the opinions of Acutok or its affiliates. To the fullest extent permitted by applicable laws, Acutok shall not be liable for any comments or any damages or expenses arising from the use or posting of comments on the website. Acutok reserves the right to monitor comments and remove any that are deemed inappropriate, offensive, or in violation of these terms.

                                </p>

                                <p>
                                    By posting comments, you warrant that you have the right to do so and possess all necessary licenses and consents. You further affirm that your comments do not infringe upon any intellectual property rights, including copyrights, patents, or trademarks of third parties. Additionally, your comments should not contain any defamatory, offensive, or unlawful material. You grant Acutok a non-exclusive license to use, reproduce, edit, and authorize others to use and edit any of your comments in any form or media.

                                </p>
                                Organizations such as government agencies, search engines, news organizations, and accredited businesses may link to our website without prior approval. These organizations can link to our homepage, publications, or other information as long as the link is not misleading and does not falsely imply sponsorship or endorsement. Other organizations, such as consumer information sources, educational institutions, and associations, may also request to link to our website, and we will consider these requests based on certain criteria, including our perception of their reputation and the context of the linking content.


                                <p>
                                    If you belong to one of the aforementioned organizations and wish to link to our website, please inform us via email, providing your name, organization name, contact information, and the URLs you intend to link to and from. Please allow 2-3 weeks for a response. Approved organizations may hyperlink to our site using our corporate name, the specific URL, or any description that makes sense within the context of their content. The use of Acutok’s logo or artwork for linking requires a trademark license agreement.

                                </p><br />


                                <p>
                                    You may not create frames around our webpages without prior approval, as this could alter the visual presentation of our website. Acutok is not responsible for any content that appears on your website. You agree to protect and defend us against any claims arising from your website, and no links should appear that could be interpreted as libelous, obscene, or infringing upon third-party rights.

                                </p>

                                <p>For information regarding your privacy, please refer to our Privacy Policy. We reserve the right to request the removal of all links or any specific link to our website. You agree to remove links upon our request. Additionally, we reserve the right to amend these terms and conditions and our linking policy at any time. By continuously linking to our website, you agree to adhere to these terms.

                                </p>

                                <p>If you encounter any offensive links on our website, you are welcome to contact us at any time. While we will consider requests to remove links, we are not obligated to respond or comply. We do not guarantee that the information on our website is accurate, complete, or up-to-date, nor do we assure the website's ongoing availability.

                                </p>

                                <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties, and conditions relating to our website and its use. Nothing in this disclaimer will limit or exclude our liability for death or personal injury, fraud or fraudulent misrepresentation, or any liabilities that may not be excluded under applicable law. The limitations and prohibitions of liability outlined in this section apply to all liabilities arising under the disclaimer, including those arising in contract, tort, or breach of statutory duty. As long as the website and its services are provided free of charge, we will not be liable for any loss or damage of any kind.

                                </p>
                            </p>
                            <p >
                                <h4 className='pageTitle'>iFrames</h4>
                                Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.
                            </p>
                            <p>
                                By submitting your personal details on any page of the Acutok website, you grant us permission to use that information for marketing promotions. This may include, but is not limited to, Google Ads, Facebook Ads, email campaigns, telemarketing, and native advertising.



                            </p>

                        </p>
                        <br /><br /><br /><br /><br />
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    )
}
export default Terms