import React, { useState } from "react";
import Banner from "../asset/images/test4.jpg";
import BannerMob from "../asset/images/glatt-mob.jpg";
import Modal from "../components/Modal";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import axios from "axios";

import Img6 from "../asset/images/page1-img_12.jpg"

import Img5 from "../asset/images/page1-img_13_glatt.png"
const Glatt = () => {
    const closeModalHandler=()=>{
        setIsVisible(!isVisible)
    }
    const initialFormData = {
        Name: '',
        Email: '',
        Mobile: '',
        Pincode: '',
        City: '',
        Address: '',
        Message: ''
    };
    const [formData, setFormData] = useState(initialFormData);
    const [isVisible, setIsVisible] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

    };

    const clearForm = (e:any) => {
        setFormData(initialFormData)
        setIsVisible(true);
        const timer = setTimeout(() => {
            setIsVisible(false);
        }, 3000);
    };
    const handleSubmit = async (e:any) => {
        e.preventDefault();
        const options = {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            },
            data: formData,
            url: 'https://acutok.com/api/api/V001/contactus'
        }
        try {
            const response = await axios(options);
            console.log('Form data submitted successfully:', response.data);
            clearForm(e)
        } catch (error) {
            clearForm(e)
            console.error('Error submitting form data:', error);
        }
    };
  return (
    <div>
      <Menu></Menu>
      {isVisible &&
    <Modal closeModalHandler={closeModalHandler}></Modal>
}
      
      <div className="clearfix"></div>
      <div className="banner relative">
                <img src={Banner} alt="banner" className="BannerImg mobHide" />
                <img src={BannerMob} alt="banner" className="BannerImg mobShow" />
                <div className="bannerText mobHide">
                    <div className="container">
                        <div className="row">
                            <div className="col-7 mobHide"></div>
                            <div className="col-5 banner_text_container innerPage_Cont">
                                <p><span className="brand_name">glatt</span> <br />
                                    {/* <span className="brand_slogan">POWER UP</span> */}
                                    </p>
                                <h3 className="brand_tag redColor">Charge it, ride it, love it!</h3>
                                <h6 className="brand_des">Don’t just ride—ride with purpose.</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      <div className="clearfix"></div>
      <section className="well-xl bg-color-1 well-sm padding-bottom-mx">
        <div className="container text-center relative">
          <div className="number_section"><h4 className="heading-4">The sensation</h4>
            <h3 className="heading-3 orangeColor"> glatt</h3>
          </div>
          {/* <span className="counter counterCount3"></span> */}
          <p>Say hello to Glatt, the all-new electric scooter designed to revolutionize the way you move around the city. Built with cutting-edge technology, sleek design, and eco-friendly features, Glatt is more than just a scooter – it's your smart, sustainable companion for the modern world. Powered by a high-efficiency electric motor, Glatt provides a smooth, responsive ride with impressive acceleration. Whether you're zipping through traffic or cruising down the boulevard, Glatt makes every journey feel effortless. Say goodbye to range anxiety. With a long-lasting lithium-ion battery, Glatt offers an impressive range that gets you where you need to go without worry. Perfect for daily commutes, weekend adventures, or just running errands around town.</p>
        </div>
      </section>
      <section className="relative">
        <div className="container-fluid">
          <div className="row">
            <div className="col-6 bgWhite paddCN mobHide">
              <div className="padCsBox">
                <p><span className="brand_name orangeColor">glatt</span> <br />
                  <span className="brand_slogan">LAUNCHED</span></p>
                <h3 className="brand_tag">Electric Thrills</h3>
                <h6 className="brand_des">Perfect blend of cutting-edge technology, sleek design, and sustainable performance</h6>
              </div>
              {/* <img src={Img2} className="BannerImg" /> */}
            </div>
            <div className="col-md-6 col-sm-12 paddCN">
              {/* <div className="padCsBox">
                <p><span className="brand_name fontWhite">GLATT</span> <br />
                  <span className="brand_slogan fontWhite">RACING CLUB</span></p>
                <h3 className="brand_tag fontWhite">free membership</h3>
                <h6 className="brand_des fontWhite">Come and experience it all yourself,
                  at the greatest motorcycle racing
                  club in the world!</h6>
              </div> */}
              {/* <img src={Img3} className="BannerImg" /> */}
              <h2 className="heading text-left lowHeading">
                            From ₹1,09,650/-
                        </h2>
                        {/* <h4>SPECIFICATIONS</h4> */}
                        <ul className="specifications">
                        <li>&rarr; Lithium Battery - 60V / 40 AMP</li>
                            <li>&rarr; Range - 100-120km</li>
                            <li>&rarr; 3.5 inch LED</li>
                            <li>&rarr; Battery warranty - 3 years</li>
                            <li>&rarr; Motor and controller warranty - 1 year</li>
                            <li>&rarr; Charging time for 0-100% - 3-4 hours</li>
                            <li>&rarr; Hydraulic Telescopic rear- double shocker with dual tube technology</li>
                            <li>&rarr; Digital speedometer Voltage 60V/72V</li>
                            <li>&rarr; Tubeless tyres and DRL light</li>
                            <li>&rarr; Front Disc/ Rear Drum</li>
                            <li>&rarr; Central locking with Anti-theft alarm, keyless Entry</li>
                            <li>&rarr; Mobile Charging USB port</li>
                            <li>&rarr; LED rear Winkers</li>
                        </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="relative">
        <img src={Img6} className="fullWidth" />
        <div className="banner_Float_div  mobHide">
          <p><span className="brand_name orangeColor">NEW</span> <br />
            {/* <span className="brand_slogan">RACING CLUB</span> */}
            </p>
          <h3 className="brand_tag">Electric vibes, eco rides!</h3>
          <h6 className="brand_des">Introducing the GLATT Electric Scooter: Power Meets Performance</h6>
        </div>
      </section>
      <section className="well-xl bg-color-1 well-sm padding-bottom-mx btm3 btt4">
        <div className="container text-center relative">
          <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6 col-sm-12">
            <p><span className="brand_slogan">GLATT</span></p>
            {/* <h3 className="brand_tag">free membership</h3> */}
            <h6 className="brand_des">Perfect for city dwellers looking for an affordable, reliable, and sustainable alternative to cars, taxis, and public transport.</h6>
            <img src={Img5} className="BannerImg" />
          </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </div>
  );
};

export default Glatt;
