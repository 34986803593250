import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Route, Routes, Link } from 'react-router-dom';

import Logo from "../Assets/logo.png";
import Modal from './Modal';
const Menu = () => {
    const [isVisible, setIsVisible] = useState(false);
    const closeModalHandler=()=>{
        setIsVisible(!isVisible)
    }
    const [expanded, setExpanded] = useState(false);
    const [isFixed, setIsFixed] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
    return (
        <>
            {isVisible &&
            <Modal closeModalHandler={closeModalHandler}></Modal>
}
<div className={`menu-bar ${isFixed ? 'fixed' : ''}`}>
            <div className="topMenu mobHide">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-3">
                        <Link to="/"><img src={Logo} alt="banner" className="logo" /></Link>
                        </div>
                        <div className="col-6 menuDesktop">
                            <ul className="">
                                <li className='menuActive'><Link to="/stark">stark</Link></li>
                                <li><Link to="/storm">storm</Link></li>
                                <li><Link to="/leistung">leistung</Link></li>
                                <li><Link to="/licht">licht</Link></li>
                                <li><Link to="/glatt">glatt</Link></li>
                            </ul>
                        </div>
                        <div className="col-3">
                            <button className="bookNowBtn" onClick={()=>setIsVisible(!isVisible)}>Book Your Test Ride &rarr;</button>
                        </div>
                    </div>
                </div>
            </div>
            <p className="notification mobHide">
          We’ve got some exciting offers exclusively for you. Upto Rs. 75000/- cashback. Terms & Conditions apply
        </p>
        </div>
            <nav class="navbar navbar-expand-lg navbar-dark bg-dark mobShow" expanded={expanded}>
            <Link to="/"><img src={Logo} alt="banner" className="logo" /></Link>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav mr-auto">
                        <li className='nav-item'>
                          <Link className="nav-link" to="/">HOME</Link>
                        </li>
                        <li className='nav-item'>
                          <Link className="nav-link" to="/stark">STARK</Link>
                        </li>
                                
                        <li className='nav-item'>
                          <Link className="nav-link" to="/storm">STORM</Link>
                          
                        </li>
                        <li class="nav-item">
                        <Link className="nav-link" to="/leistung">LEISTUNG</Link>
                        </li>
                        <li class="nav-item">
                        <Link className="nav-link" to="/glatt">GLATT</Link>
                        </li>
                        <li class="nav-item">
                        <Link className="nav-link" to="/licht">LICHT</Link>
                        </li>
                        <button className="bookNowBtn mbMarginBtm">Book Your Test Ride &rarr;</button>
                    </ul>
                </div>
            </nav>
            <button className="bookNowBtnStickey" onClick={()=>setIsVisible(!isVisible)}>Buy Now &rarr;</button>
            <div>
        <p className="notification mobShow">
          We’ve got some exciting offers exclusively for you. Upto Rs. 75000/- cashback. Terms & Conditions apply
        </p>
      </div>
        </>
    )
}
export default Menu