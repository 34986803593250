import React, { useState } from "react";
import Marquee from "react-fast-marquee";
import Banner from "../Assets/contact-us.jpg";
import BannerMob from "../Assets/modal.jpg";
import Img1 from "../Assets/b3.jpg";
import Bike1 from "../Assets/bike1-home.JPG";
import Bike2 from "../Assets/bike2-home.JPG";
import Modal from "../components/Modal";
import P1 from '../Assets/homeImg1.jpg'
import P2 from '../Assets/homeImg2.jpg'
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import axios from "axios";
const Contact = () => {
    const closeModalHandler=()=>{
        setIsVisible(!isVisible)
    }
    const initialFormData = {
        Name: '',
        Email: '',
        Mobile: '',
        Pincode: '',
        City: '',
        Address: '',
        Message: ''
    };
    const [formData, setFormData] = useState(initialFormData);
    const [isVisible, setIsVisible] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

    };

    const clearForm = (e:any) => {
        setFormData(initialFormData)
        setIsVisible(true);
        const timer = setTimeout(() => {
            setIsVisible(false);
        }, 3000);
    };
    const handleSubmit = async (e:any) => {
        e.preventDefault();
        const options = {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            },
            data: formData,
            url: 'https://acutok.com/api/api/V001/contactus'
        }
        try {
            const response = await axios(options);
            console.log('Form data submitted successfully:', response.data);
            clearForm(e)
        } catch (error) {
            clearForm(e)
            console.error('Error submitting form data:', error);
        }
    };
  return (
    <div>
      <Menu></Menu>
      {isVisible &&
    <Modal closeModalHandler={closeModalHandler}></Modal>
}
      
      <div className="clearfix"></div>
      <div className="banner relative">
        <img src={Banner} alt="banner" className="BannerImg mobHide" />
        <img src={BannerMob} alt="banner" className="BannerImg mobShow" />
        <div className="bannerText mobHide">
          <h1 className="title">Reach Out<br/><span className="redColor">Today..</span></h1>
        </div>
        {/* <div className="bottomGradient"></div> */}
        <div className="headerWave">
        <div>
            <svg className="waves" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
                <defs>
                    <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
                </defs>
                <g className="parallax">
                    <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(0,0,0,0.7"></use>
                    <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(0,0,0,0.5)"></use>
                    <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(0,0,0,0.3)"></use>
                    <use xlinkHref="#gentle-wave" x="48" y="7" fill="#000"></use>
                </g>
            </svg>
        </div>

    </div>
      </div>
      <div className="clearfix"></div>

      {/* <div className="container-fluid">
        <div className="row">
          <div className="col-12 padding-topCstm1">
            <span className="opText"></span>
            <h2 className="heading text-left">
              A New Identity For{" "}
              <span className="redColor">
                Electric
                <br />
                Motorcycles
              </span>
            </h2>
          </div>
          <div className="clearfix"></div>
        </div>
      </div> */}
      <div className="container">
        <div className="row">
          <div className="clearfix"></div>
          <div className="col-md-6 daivik padding-topCstm1">
          <div className=' padding15 colorWhite'>
                        <h3 className='modalTitle'>Get in Touch with Us!</h3>
                        <p className='colorWhite'>We’re here to help! Whether you have a question, feedback, or need assistance, please fill out the form below. Our team will get back to you as soon as possible. Your inquiries are important to us!</p>
                        <form onSubmit={handleSubmit}>
                            <p className='formLabel colorWhite'>Name *</p>
                            <input type="text" className='inputStyle' placeholder='Enter your name..' name='Name' value={formData.Name} onChange={handleChange}required />
                            <p className='formLabel'>Email Id *</p>
                            <input type="email" className='inputStyle' placeholder='Enter Email Id..' name='Email' value={formData.Email} onChange={handleChange} required/>
                            <p className='formLabel'>Contact Number *</p>
                            <input type="text" className='inputStyle' placeholder='Enter Contact number..' name='Mobile' value={formData.Mobile} onChange={handleChange}required />
                            <p className='formLabel'>PIN code</p>
                            <input type="text" className='inputStyle' placeholder='Enter PIN code..' name='Pincode' value={formData.Pincode} onChange={handleChange} />
                            <button type='submit' className='submitBtn'>SUBMIT</button>
                        </form>
                    </div>
          </div>
          <div className="col-md-6 padding-topCstm1">
          <h3 className='modalTitle colorWhite'>Find Us!</h3>
          <p className='colorWhite'>Looking for our location? Use the map below to easily navigate to our office. We can’t wait to welcome you!</p>
          <div className="responsive-map">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3782.2431289165024!2d73.76479597399268!3d18.563074467876884!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bfbbdb539255%3A0x85d7db76680bc60e!2sACUTOK%20PRIVATE%20LIMITED!5e0!3m2!1sen!2sin!4v1727632799288!5m2!1sen!2sin" width="600" height="450" style={{border:0}}  loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
</div>
          </div>
          <div className="clearfix"></div>
        </div>
      </div>
      
      <Footer></Footer>
    </div>
  );
};

export default Contact;
