import React, { useState } from 'react';

import Banner from "../Assets/disclaimer.jpg";
import Menu from '../components/Menu';
import Footer from '../components/Footer';
const Disclaimer = () => {

    return (
        <>
            <Menu></Menu>
            <div className="clearfix"></div>
      <div className="banner relative">
        <img src={Banner} alt="banner" className="BannerImg mobHide" />
        <img src={Banner} alt="banner" className="BannerImg mobShow" />
        <div className="bannerText mobHide">
          <h1 className="title">Disclaimer..</h1>
        </div>
        <div className="bottomGradient"></div>
      </div>
      <div className="clearfix"></div>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <p className='pageData'>
                            For any inquiries or further information regarding our site’s disclaimer, please feel free to contact us at info@acutok.com.


                            <p >
                                <h4 className='pageTitle'>Disclaimers for Acutok
                                </h4>
                                All information provided on this website, https://acutok.com, is published in good faith for general informational purposes only. Acutok does not guarantee the completeness, reliability, or accuracy of this information. Any actions you take based on the information found on our website are strictly at your own risk. Acutok will not be liable for any losses or damages arising from the use of our website.
                                <p>
                                    Through our website, you may find hyperlinks that direct you to external sites. While we strive to provide quality links to useful and ethical websites, we have no control over the content and nature of these external sites. The inclusion of any links does not imply our endorsement of all content found on those sites. Please note that site owners and content may change without notice, and we may not always have the opportunity to remove a link that is no longer valid.

                                </p>
                                When you leave our website, be aware that other sites may have different privacy policies and terms that are beyond our control. We encourage you to review the Privacy Policies and Terms of Service of these external sites before engaging in any business or sharing any information.
                                <p>
                                    <h4 className='pageTitle'>Consent</h4>
                                    By using our website, you consent to this disclaimer and agree to its terms.

                                </p>

                                <p>
                                    <h4 className='pageTitle'>Update</h4>
                                    If we update, amend, or make changes to this document, those changes will be prominently posted here for your review.
                                </p>
                            </p>
                        </p>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    )
}
export default Disclaimer