import React from 'react';
import './Footer.css';
import logo from "../Assets/logo.png";
import { Facebook, Twitter, Youtube, Linkedin, Instagram } from 'lucide-react';
import { HashRouter as Router, Route, Routes, Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-main mobHide">

          <div className="footer-columns">
            <div className="footer-column">
              <h3>STARK</h3>
              <p>Perfect blend of cutting-edge technology, sleek design, and sustainable performance
              </p>

              <h3>STORM</h3>
              <p>Engineered to deliver unmatched performance, offering riders the freedom</p>

              <h3>LEISTUNG</h3>
              <p>Delivers a smooth, responsive ride with the power of a high-torque motor and a long-lasting battery.</p>
            </div>

            <div className="footer-column">
              <h3>GLATT</h3>
              <p>High-capacity battery ensures long-distance rides without interruption.</p>

              <h3>LICHT</h3>
              <p>Empowers you to go further with less effort—faster, smarter, and greener.</p>
            </div>
          </div>
        </div>

        <div className="footer-links">
          <div className="footer-column">
            <h3>QUICK LINKS</h3>
            <ul className='links_cs'>
            <li><Link to="/privacy">Privacy Policy</Link></li>
              <li><Link to="/disclaimer">Disclaimer</Link></li>
              <li><Link to="/terms">Terms & Conditions</Link></li>
              <li><Link to="#">Service Stations</Link></li>
              <li><Link to="/contact">Contact Us</Link></li>

            </ul>
          </div>

          <div className="footer-column">
            <h3>PRODUCTS</h3>
            <ul className='links_cs'>
              <li><Link to="/stark">stark</Link></li>
              <li><Link to="/storm">storm</Link></li>
              <li><Link to="/leistung">leistung</Link></li>
              <li><Link to="/licht">licht</Link></li>
              <li><Link to="/glatt">glatt</Link></li>
            </ul>
          </div>
        </div>

        <div className="footer-contact  mobHide">
        <Link to="/contact"><button className="contact-button">CONTACT US</button></Link>
          <div className="footer-logo">
          <img src={logo}alt="Progress" />
          
        </div>
          <ul>
            <li>Plot No. 36 & 37. Sr No. 101/1/1 P, Banglore Highway, near Mercedes Showroom, Baner, Mumbai, Pune, Maharashtra 411045</li>
          </ul>
          <div className="social-cstm">
            <Link to="https://www.facebook.com/profile.php?id=61565126531960" target='_blank' rel="noopener noreferrer"><Facebook size={20} /></Link>
            <Link to="#"><Twitter size={20} /></Link>
            <Link to="#"><Youtube size={20} /></Link>
            <Link to="#"><Linkedin size={20} /></Link>
            <Link to="/https://www.instagram.com/acutok_ev/" target='_blank' rel="noopener noreferrer"><Instagram size={20} /></Link>
          </div>
        </div>
      </div>

      <div className="footer-bottom">

        {/* <div className="footer-logo">
          <img src={logo}alt="Progress" />
          
        </div> */}

        {/* <div className="footer-social">
          <Facebook size={24} />
          <Twitter size={24} />
          <Youtube size={24} />
          <Linkedin size={24} />
        </div> */}

        <div
          className="footer-legal">
          {/* <span>Terms of Use | Privacy Policy | Trademarks | License Agreements</span> */}
          <p>Copyright © 2024, Acutok Pvt. Ltd. All Rights Reserved.</p>
        </div>

      </div>

    </footer>
  );
};

export default Footer;

