import React, { useState } from 'react';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import Banner from "../Assets/banner2.jpg";
const Privacy = () => {

    return (
        <>
            <Menu></Menu>
            <div className="clearfix"></div>
      <div className="banner relative">
        <img src={Banner} alt="banner" className="BannerImg mobHide" />
        <img src={Banner} alt="banner" className="BannerImg mobShow" />
        <div className="bannerText mobHide">
          <h1 className="title">Privacy Policy..</h1>
        </div>
        <div className="bottomGradient"></div>
      </div>
      <div className="clearfix"></div>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <h2 className='pageTitle'>Privacy Policy</h2>
                        <p className='pageData'>
                        Effective Date: July 1, 2024
                            <br /><br />
                            At Acutok Ltd, accessible via acutok.com and acutok.in, we prioritize the privacy of our visitors. This Privacy Policy outlines the types of information we collect, how we use it, and your rights regarding that information. If you have any questions or need further information about our Privacy Policy, please don't hesitate to contact us.
                           <br /><br /> <p >
                           Acutok Ltd employs standard log file procedures when visitors access our websites. This process involves logging information such as IP addresses, browser types, Internet Service Providers (ISPs), date and time stamps, referring and exit pages, and the number of clicks. This data is utilized for analyzing trends, administering the site, tracking user movements, and gathering demographic information, but it is not linked to any personally identifiable data.
                        </p>

                        <p >
                        As a third-party vendor, Google utilizes DART cookies to serve ads based on visitors' previous interactions with our site and other websites. Visitors can decline the use of DART cookies by reviewing Google's Privacy Policy. It’s important to note that our Privacy Policy does not extend to other advertisers or websites, so we encourage you to consult the respective Privacy Policies of these third-party ad servers for more detailed information and opt-out instructions.
<br /><br />
                        </p>

                        <p >
                        We are committed to protecting children's privacy online. We encourage parents and guardians to monitor their children’s internet activities. Acutok Ltd does not knowingly collect personal identifiable information from children under 13. If you believe your child has provided us with such information, please contact us immediately so we can take appropriate action.
                            </p>
                        
                        <p >
                        This Privacy Policy applies only to our online activities and is relevant for visitors to our website regarding the information they share with us. It does not apply to information collected offline or through other channels. By using our website, you consent to our Privacy Policy and agree to its terms and conditions.
                        </p>
                        <p >
                        Acutok has developed the Acutok app as a free service, enabling users to connect their vehicles to mobile devices to track vehicle health, analyze trips, predict range, and more. To enhance your experience with our app, we may require certain personally identifiable information, such as your name, contact information, phone number, location data, storage access, and background location for navigation. This information will be retained and used as outlined in this policy.
</p>
                        <p >
                        The app may also utilize third-party services that could collect information to identify you. For more information, please refer to the privacy policies of service providers such as Google Play Services, Google Analytics for Firebase, and Firebase Crashlytics. Additionally, the app uses location data for trip planning and navigation purposes, helping determine the fastest routes based on your preferences.</p>
                        <p >
                        In the event of an error in the app, we collect log data that may include your device's IP address, device name, operating system version, app configuration, and the date and time of use. While this service does not explicitly use cookies, third-party libraries may. Users can choose to accept or refuse cookies, but refusing them may limit the functionality of some service portions.
                        </p>
                        <p >
                        We may employ third-party companies and individuals to facilitate our service, provide it on our behalf, perform service-related tasks, or analyze how our service is used. These third parties have access to your personal information solely for the tasks they perform on our behalf and are obligated to protect this information.
                        </p>
                        <p >We value your trust in providing us with your personal information and strive to use commercially acceptable means to protect it. However, please remember that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security. Our services are not directed at anyone under the age of 13, and we do not knowingly collect personal information from children in that age group. If we discover that a child has provided us with personal information, we will delete it promptly. Parents or guardians aware of such instances should contact us to assist in necessary actions.
                        </p>
                        <p>
                        We may update our Privacy Policy periodically, and we encourage you to review this page regularly for any changes. Any updates will be posted here. For questions or suggestions regarding our Privacy Policy, please contact us at info@acutok.com. By submitting your personal details on any page of the Acutok website, you permit us to use this information for marketing promotions, which may include Google Ads, Facebook Ads, email campaigns, telemarketing, and native advertising.
                        </p>       
                        </p>                
                        <br /><br /><br /><br /><br />
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    )
}
export default Privacy