import React, { useState } from "react";
import Marquee from "react-fast-marquee";
import Banner from "../asset/images/test5.jpg";
import BannerMob from "../asset/images/stark-mob.jpg";
import Img1 from "../Assets/sample-image.jpg";
import Bike1 from "../Assets/bike1-home.JPG";
import Modal from "../components/Modal";
import P1 from '../Assets/homeImg1.jpg'
import P2 from '../Assets/homeImg2.jpg'
import Menu from "../components/Menu";
import Img2 from "../Assets/stark-banner.jpg";
import Battery from "../Assets/battery.png";
import Brakes from "../Assets/brakes.png";
import Suspension from "../Assets/suspension.png";
import Power from "../Assets/power.png";
import C1 from "../Assets/c1.jpg";
import C2 from "../Assets/c4.jpg";
import Img6 from "../asset/images/page1-img_02-02.jpg"
import C3 from "../Assets/c3.jpg";
import Footer from "../components/Footer";
const Stark = () => {
    const [isVisible, setIsVisible] = useState(false);
    const closeModalHandler = () => {
        setIsVisible(!isVisible)
    }

    return (
        <div>
            <Menu></Menu>
            {isVisible &&
                <Modal closeModalHandler={closeModalHandler}></Modal>
            }

            <div className="clearfix"></div>
            <div className="banner relative">
                <img src={Banner} alt="banner" className="BannerImg mobHide" />
                <img src={BannerMob} alt="banner" className="BannerImg mobShow" />
                <div className="bannerText mobHide">
                    <div className="container">
                        <div className="row">
                            <div className="col-8 mobHide"></div>
                            <div className="col-4 banner_text_container innerPage_Cont">
                                <p><span className="brand_name">STARK</span> <br />
                                    <span className="brand_slogan">POWER UP</span></p>
                                <h3 className="brand_tag redColor">Ride the Future</h3>
                                <h6 className="brand_des">Come and experience it all yourself,
                                    at the greatest motorcycle in the world!</h6>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="bottomGradient"></div> */}
                {/* <div className="headerWave">
                    <div>
                        <svg className="waves" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
                            <defs>
                                <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
                            </defs>
                            <g className="parallax">
                                <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(0,0,0,0.7"></use>
                                <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(0,0,0,0.5)"></use>
                                <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(0,0,0,0.3)"></use>
                                <use xlinkHref="#gentle-wave" x="48" y="7" fill="#000"></use>
                            </g>
                        </svg>
                    </div>

                </div> */}
            </div>
            <div className="clearfix"></div>
            {/* <div className="container-fluid bannerTyreStark padding-topCstm1">
                <div className="row">
                    <div className="col-12">
                        <span className="opText"></span>
                        <h2 className="heading text-left">
                        Introducing STARK:{" "}<br/>
                            <span className="redColor">
                            Power Meets Precision
                            </span>
                        </h2>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col">
                        <div className="row">
                            <div className="col-3 mobHide"></div>
                            <div className="col-md-8 mobComPadd">
                                <p className="text1">Elevate your ride with the STARK electric motorbike, where unmatched power meets precision engineering. Designed for enthusiasts who crave speed and agility, STARK features a robust electric motor for exhilarating acceleration, smart connectivity for seamless navigation, and an ergonomic design for ultimate comfort. Perfect for urban streets or open highways, STARK is your gateway to a new era of sustainable riding. Embrace the journey and ride boldly with STARK!</p>
                            </div>
                        </div>

                    </div>
                    
                </div>
            </div> */}
            <section className="relative mobHide">
        <img src={Img6} className="fullWidth" />
        <div className="banner_Float_div">
          <p><span className="brand_name redColor">STARK</span> <br />
            <span className="brand_slogan">LAUNCHED</span></p>
          <h3 className="brand_tag">Electric Thrills</h3>
          <h6 className="brand_des">Perfect blend of cutting-edge technology, sleek design, and sustainable performance</h6>
        </div>
      </section>
            <div className="container padding-topCstm1">
                <div className="row">
                    {/* <div className="col-12">
          <img src={Img1} alt="banner" className="BannerImg mobHide" />
          </div> */}
                    <div className="col-md-4 col-sm-12">
                        <div className='boxCont'>
                        <div className='width50'>
                            <h1>Powerful Battery</h1>
                            <p>High-capacity battery ensures extended range for worry-free rides.</p>
                            </div>
                            <img src={Battery} alt="banner" className="FloatImg" />
                        </div>
                    </div>
                    <div className="col-md-8 col-sm-12">
                        <div className='boxCont'>
                            <div className='width50'>
                                <h1>Ergonomic Comfort</h1>
                                <p>Designed for comfort and performance, STARK features an adjustable seat and intuitive controls, allowing riders of all sizes to enjoy a relaxed and enjoyable ride on any adventure.</p>
                            </div>
                            <img src={Suspension} alt="banner" className="FloatImg" />
                        </div>
                    </div>
                    <div className="col-md-8 col-sm-12">
                        <div className='boxCont'>

                            <div className='width50'>
                                <h1>Powerful Electric Motor</h1>
                                <p>Unleash your potential with STARK’s robust electric motor, delivering impressive torque and speed for an exhilarating riding experience on any terrain.</p>
                            </div>
                            <img src={Power} alt="banner" className="FloatImg" />
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className='boxCont'>
                        <div className='width50'>
                            <h1>Wheel Design</h1>
                            <p>STARK’s wheels ensure maximum grip and stability in all conditions.</p>
                            </div>
                            <img src={Brakes} alt="banner" className="FloatImg" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="clearfix"></div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 daivik mobHide">
                        <img src={Bike1} alt="banner" className="BannerImg borderImg2" />
                    </div>
                    <div className="col-md-6 padding-topCstm1">
                        {/* <h2 className="heading text-left">
                            ACUTOK {" "}
                            <span className="redColor">
                                STARK
                            </span>
                        </h2> */}
                        <h2 className="heading text-left lowHeading">
                            From ₹2,37,850
                        </h2>
                        {/* <h4>SPECIFICATIONS</h4> */}
                        <ul className="specifications">
                            <li>&rarr; Lithium Battery - 72V / 45 AMP</li>
                            <li>&rarr; Range - 135+</li>
                            <li>&rarr; 4 inch LCD display with IoT enabled 1 wire connectivity</li>
                            <li>&rarr; Battery warranty - 3 years</li>
                            <li>&rarr; Motor and controller warranty - 1 year</li>
                            <li>&rarr; Charging time for 0-100% - 3-4 hours</li>
                            <li>&rarr; Hydraulic suspension</li>
                            <li>&rarr; Digital speedometer</li>
                            <li>&rarr; Tubeless tyres and DRL light</li>
                            <li>&rarr; LED lights and fast charging</li>
                            <li>&rarr; Front/Rear Disc brakes</li>
                        </ul>

                        <button className="bookNowBtnHome" onClick={() => setIsVisible(!isVisible)}>Book Your Test Ride &rarr;</button>
                    </div>
                </div>
            </div>
            <div className="container">

                <div className="col-12  padding-topCstm1">
                    {/* <h2 className="heading text-center">
                        Effortless Style,<br />{" "}
                        <span className="redColor">
                            Electric Power.
                        </span>
                    </h2> */}
                </div>
                <div className="clearfix"></div>
                <div className="row">
                    <div className="col-md-3 col-sm-12  padding-topCstm1 mob15">
                        <img src={C1} alt="banner" className="BannerImg borderImg1" />
                    </div>
                    <div className="col-md-6 col-sm-12  padding-topCstm1 mob15">
                        <img src={C2} alt="banner" className="BannerImg borderImg1" />
                    </div>
                    <div className="col-md-3 col-sm-12  padding-topCstm1 mob15">
                        <img src={C3} alt="banner" className="BannerImg borderImg1" />
                    </div>
                    <div className="clearfix"></div>

                </div>
            </div>

            <Footer></Footer>
        </div>
    );
};

export default Stark;
